import React from 'react'
import Layout from '../layout/Layout'

function Contactos() {
  return (
    <Layout>
        <div>Contactos</div>
    </Layout>
  )
}

export default Contactos