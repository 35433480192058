const DetriAccesos =  [
            {
                id: 1,
                Nombre: 'Mision',
                Link: 'Mision',
            },
            {
                id: 2,
                Nombre: 'Autoridades',
                Link: 'Autoridades',
            },
            {
                id: 3,
                Nombre: 'Personal Docente',
                Link: 'PersonalDocente',
            },
            {
                id: 4,
                Nombre: 'Plan Estrategico',
                Link: 'PlanEstrategico',
            },
            {
                id: 5,
                Nombre: 'Directorio',
                Link: 'Directorio',
            },
            {
                id: 6,
                Nombre: 'Ubicacion',
                Link: 'Ubicacion',
            },
        ]
  

export default DetriAccesos;
